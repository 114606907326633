window.$ = jQuery;

(function ($, sr) {
    var debounce = function (func, threshold, execAsap) {
        var timeout;
        return function debounced() {
            var obj = this, args = arguments;

            function delayed() {
                if (!execAsap)
                    func.apply(obj, args);
                timeout = null;
            };
            if (timeout)
                clearTimeout(timeout);
            else if (execAsap)
                func.apply(obj, args);

            timeout = setTimeout(delayed, threshold || 100);
        };
    }
    // smartresize
    jQuery.fn[sr] = function (fn) {
        return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
    };
})(jQuery, 'smartresize');

window.manniello_refresh_size_queries = window.manniello_refresh_size_queries || function () {
    // Avoid using var
    // It get once if is mobile or not, to change this add in resize
    window.scrollbarwidth = manniello_get_scroll_bar_width();
    window.window_width = $(window).width() + scrollbarwidth;
    window.window_height = $(window).height();
    window.is_phone = (window_width < 768);
    window.is_mobile = (window_width < 992);
    window.is_tablet_portrait = (window_width >= 768 && window_width < 992);
    window.is_tablet_landscape = (window_width >= 992 && window_width < 1200 && window_height <= 768);
    window.is_tablet = is_tablet_portrait || is_tablet_landscape;
    window.is_desktop = (window_width >= 992);
    window.is_desktop_large = (window_width >= 1200);
}

function manniello_get_scroll_bar_width() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);
    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";
    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);
    var widthWithScroll = inner.offsetWidth;
    // remove divs
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
}

// Load Delay JS or User interaction Ultra - Speed
function manniello_ewdelay_or_interaction_js() {
    var ewDelayTimer = setTimeout(function () {
        ewLoadDelayedScripts()
        ewTriggerAssets()
    }, 5000);
    var ewUserInteractions = ["keydown", "mousemove", "wheel", "touchmove", "touchstart", "touchend"];
    ewUserInteractions.forEach(function (event) {
        window.addEventListener(event, ewTriggerDelayedScripts, {passive: !0})
    });

    function ewTriggerDelayedScripts() {
        var body = document.querySelector('body');
        ewLoadDelayedScripts();
        ewTriggerAssets();
        setTimeout(function () {
            // Wait to load the fbsdk files
            manniello_web_sdk_offers();
        }, 1500);
        // With the next rule can use slick with Lazy. Must use settimeout
        // Also needs to finish the fade animation (progressive img) before slick loads, for that 1200
        // If no the fade progressive image will load twice
        if (body.classList.contains('home')) {
            loadAssetsFonts();
            setTimeout(function () {
                manniello_slick_default_gallery();
                manniello_slick_fullwidth_lazy();
                console.log('Slick Loading with Lazy. Deskt & Home');
            }, 1500);
        }

        if (body.classList.contains('manniello-group-theme')) {
            // Load the Booking NoPrices All
            manniello_booking_group();
        }

        clearTimeout(ewDelayTimer);
        ewUserInteractions.forEach(function (event) {
            window.removeEventListener(event, ewTriggerDelayedScripts, {passive: !0});
        });
    }

    function ewTriggerAssets() {
        var lazy_selected_bgs = Array.from(document.querySelectorAll('.lazy_ew'));
        if (lazy_selected_bgs != null) {
            lazy_selected_bgs.forEach(image => {
                if (image.getAttribute('data-style') != null) {
                    image.setAttribute('style', image.getAttribute('data-style'));
                    image.removeAttribute('data-style');
                }
            })
        }
        var lazy_selected_imgs = Array.from(document.querySelectorAll('.lazy_ew_img'));
        if (lazy_selected_imgs != null) {
            lazy_selected_imgs.forEach(image => {
                // Avoid changing the src twice
                if (!image.classList.contains('loaded')) {
                    image.setAttribute('src', image.getAttribute('data-src'));
                    image.classList.add('loaded');
                }
            })
        }
    }

    function ewLoadDelayedScripts() {
        document.querySelectorAll("script[data-ewdelayedscript]").forEach(function (elem) {
                elem.setAttribute("src", elem.getAttribute("data-ewdelayedscript"));
                // Optional remove the last attribute data-ewdelayedscript
                elem.removeAttribute("data-ewdelayedscript");
            }
        );
    }
}

function manniello_run_parallax_js() {
    var parallax_window = jQuery('.parallax-window');
    // Check if jQuery exists (removed for errors)
    if (parallax_window.length && typeof jQuery.fn.parallax != 'undefined') {
        parallax_window.parallax({
            naturalWidth: 2200,
            naturalHeight: 1100,
            overScrollFix: true,
            iosFix: true,
        });
    }
}

function manniello_slick_fullwidth_lazy() {
    ;(function ($) {
        var slick_fullwidth = jQuery('.slider-slideshow');
        if (slick_fullwidth.length > 0) {
            // Fires before slide change
            slick_fullwidth.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                var nextSlideImg = jQuery('.slider-slideshow .slick-slide[data-slick-index="' + nextSlide + '"]');
                var lazySlides = function (el, attr, lazyAttr) {
                    nextSlideImg
                        .find(el)
                        .each(function (i, img) {
                            $img = $(img);
                            $img.attr(attr, $img.data(lazyAttr));
                        });
                };
                lazySlides('picture source[data-lazy-srcset]', 'srcset', 'lazy-srcset');
                lazySlides('img[data-lazy]', 'src', 'lazy');
            });

            // Second function Ultra Speed, load after X seconds and mouse move all the sliders. Max 4 in ACF
            setTimeout(function () {
                document.addEventListener('mousemove', loadSlickMouse);
                // Passive flag true to dont preventDefault improve perfomance
                document.addEventListener('touchstart', loadSlickMouse, {passive: true});
                var nextSlidersImg = jQuery('.slider-slideshow .item-slideshow');

                function sliderImages(el, attr, lazyAttr) {
                    nextSlidersImg
                        .find(el)
                        .each(function (i, img) {
                            $img = $(img);
                            $img.attr(attr, $img.data(lazyAttr));
                        });
                    document.removeEventListener('mousemove', loadSlickMouse);
                    document.removeEventListener('touchstart', loadSlickMouse);
                };

                function loadSlickMouse() {
                    sliderImages('picture source[data-lazy-srcset]', 'srcset', 'lazy-srcset');
                    sliderImages('img[data-lazy]', 'src', 'lazy');
                }

            }, 6000);
        }
    })(jQuery);
}

function manniello_slick_default_gallery() {
    if ('slick' in jQuery.fn && typeof jQuery.fn.slick === 'function') {

        var slider_main = jQuery('.slider-slideshow');
        if (slider_main.length) {
            var options = {
                rows: 0,
                // autoplay: true,
                autoplay: true,
                autoplaySpeed: 5000,
                arrows: false,
                lazyLoad: false,
                fade: true,
                speed: 3000,
                // cssEase: 'ease-out',
                waitForAnimate: false,
                pauseOnHover: false,
                pauseOnFocus: false,
                pauseOnDotsHover: false,
                rtl: jQuery('html').attr('dir') == 'rtl',
                responsive: [
                    {
                        breakpoint: 999,
                        settings: {
                            // autoplay: false,
                            autoplaySpeed: 5000
                        }
                    }]
            };
            slider_main.slick(options);
        }

        var slider_half_check = jQuery('.slider-half');
        if (slider_half_check.length && !slider_half_check.hasClass('room-slider-half')) {
            var options = {
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                // Problems with webSDK if is infinite: true
                infinite: false,
                // The half is solved with padding 0 20%..
                nextArrow: '<a class="slick-arrows next-hor up-slider" href="javascript:void(0)"></a>',
                prevArrow: '<a class="slick-arrows prev-hor up-slider" href="javascript:void(0)"></a>',
                responsive: [
                    {
                        breakpoint: 761,
                        settings: {
                            slidesToShow: 1
                        }
                    }]
            };
            // Reload the querySelectorAll from IO to get the Cloned elements added by Slick
            slider_half_check.on('init', function (event, slick, direction) {
                var imagesSliderHalf = Array.from(document.querySelectorAll('.slider-half .lazy_img'));
                manniello_lazy_load_img(imagesSliderHalf);
            }).slick(options);
        }

        var slider_home_half_check = jQuery('.home-slider-half');
        if (slider_home_half_check.length) {
            var options = {
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 1,
                // Min items: 3
                initialSlide: 2,
                nextArrow: '<a class="slick-arrows next-hor up-slider" href="javascript:void(0)"></a>',
                prevArrow: '<a class="slick-arrows prev-hor up-slider" href="javascript:void(0)"></a>',
                rtl: jQuery('html').attr('dir') == 'rtl',
                responsive: [
                    {
                        breakpoint: 761,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                        }
                    },
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 2,
                        }
                    }]
            };
            slider_home_half_check.on('init', function (event, slick, direction) {
                var imagesHomeSlider = Array.from(document.querySelectorAll('.home-slider-half .lazy_img'));
                manniello_lazy_load_img(imagesHomeSlider);
            }).slick(options);
            // slider_home_half_check.slick(options);
        }

        var img_slider_check = jQuery('.add-img-block');
        if (img_slider_check.length) {
            var options = {
                rows: 0,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 600,
                fade: true,
                cssEase: 'ease-in',
                infinite: false,
                // Cant change, empty images
                // initialSlide: 2,
                nextArrow: '<a class="slick-arrows next-hor with-slider" href="javascript:void(0)"></a>',
                prevArrow: '<a class="slick-arrows prev-hor with-slider" href="javascript:void(0)"></a>',
            };
            img_slider_check.slick(options)
        }

        var discover_block_check = jQuery('.slider-discover');
        if (discover_block_check.length) {
            var options = {
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                nextArrow: '<a class="slick-arrows next-hor up-slider" href="javascript:void(0)"></a>',
                prevArrow: '<a class="slick-arrows prev-hor up-slider" href="javascript:void(0)"></a>',
                responsive: [
                    {
                        breakpoint: 700,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                        }
                    }]
            };
            discover_block_check.slick(options);
        }

        var restaurant_block_check = jQuery('.slider-restaurants');
        if (restaurant_block_check.length) {
            var options = {
                rows: 0,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                speed: 600,
                fade: true,
                cssEase: 'ease-in',
                nextArrow: '<a class="slick-arrows next-hor top-slider" href="javascript:void(0)"></a>',
                prevArrow: '<a class="slick-arrows prev-hor top-slider" href="javascript:void(0)"></a>',
            };
            restaurant_block_check.slick(options);
        }


        var gallery_check = jQuery('.gallery-default');
        if (gallery_check.length) {
            // Fix bug initialSlide when is less than 2 items
            var count_sliders = jQuery('.gallery-default .gallery-item').length;
            count_sliders = (count_sliders > 2) ? '1' : '0';
            var options = {
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: parseInt(count_sliders),
                infinite: true,
                nextArrow: '<a class="slick-arrows next-hor after-slider" href="javascript:void(0)"></a>',
                prevArrow: '<a class="slick-arrows prev-hor after-slider" href="javascript:void(0)"></a>',
                rtl: jQuery('html').attr('dir') == 'rtl',
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                        }
                    }]
            };
            // ew Fix White blink with lazy load
            // ondemand, progressive don't work. lazy_img neither
            gallery_check.on('init', function (event, slick, direction) {
                var imagesGallery = Array.from(document.querySelectorAll('.gallery-item .lazy_ew_img'));
                manniello_lazy_load_img(imagesGallery);
            }).slick(options);
            // gallery_check.slick(options);
        }

        var single_room_check = jQuery('.slick-single-rooms');
        if (single_room_check.length) {
            var options = {
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0,
                infinite: false,
                nextArrow: '<a class="slick-arrows next-hor up-slider" href="javascript:void(0)"></a>',
                prevArrow: '<a class="slick-arrows prev-hor up-slider" href="javascript:void(0)"></a>',
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                        }
                    }]
            };
            // With infinite false dont need the to reload images
            single_room_check.slick(options);
        }
    }
}

function manniello_fancybox_callback() {

    var mediaFullDesktop = window.matchMedia('(min-width: 1400px)');
    /* Set individual effect or options */
    var fancy_block_check = $('[data-fancybox="gallery-photogallery"], [data-fancybox="new-photogallery"]');
    if (fancy_block_check.length && typeof $.fancybox !== 'undefined') {
        fancy_block_check.fancybox({
            animationEffect: "zoom-in-out",
            animationDuration: 350,
            transitionEffect: "fade",
            transitionDuration: 1500,
            wheel: false,
            arrows: true,
            buttons: [
                'fullScreen',
                'close'
            ],
            youtube: {
                controls: 0,
                showinfo: 0
            },
            vimeo: {
                color: 'f00'
            },
            touch: false,
            // backFocus: false,
            loop: true,
            // hash: false,
            // helpers: {
            //     overlay: {
            //         locked: false
            //     }
            // },
            mobile: {
                // Fix mobile on next/before pass two slides instead of one
                preventCaptionOverlap: !1,
                // idleTime: !1,
                clickContent: function (t, e) {
                    return "image" === t.type && "zoom";
                },
                clickSlide: function (t, e) {
                    return "image" === t.type && "close";
                },
                // touch: true,
                touch: false,
                // arrows: false,
            },
            // Disable double click, moves two images in mobile and tablet
            afterShow: function (instance, current) {
                if (!mediaFullDesktop.matches) {
                    jQuery(".fancybox-navigation button").on('click', function (event) {
                        event.preventDefault();
                        jQuery(this).prop('disabled', true);
                    });
                }
            }
        });
    }


    /* Set individual effect or options. Changed video animation on individual video with data-.. */
    var fancy_gallery_check = $('[data-fancybox="default-gallery"]:not(.slick-cloned)');
    if (fancy_gallery_check.length && typeof $.fancybox !== 'undefined') {
        fancy_gallery_check.fancybox({
            animationEffect: "zoom-in-out",
            animationDuration: 350,
            transitionEffect: "fade",
            transitionDuration: 1500,
            wheel: false,
            arrows: true,
            // Avoid showing the image number (Slick clones)
            // With not slick cloned resolved
            // infobar: false,
            buttons: [
                'fullScreen',
                'close'
            ],
            touch: false,
            backFocus: false,
            loop: true,
            hash: false,
            helpers: {
                overlay: {
                    locked: false
                }
            },
            mobile: {
                // Fix mobile on next/before pass two slides instead of one
                preventCaptionOverlap: !1,
                // idleTime: !1,
                clickContent: function (t, e) {
                    return "image" === t.type && "zoom";
                },
                clickSlide: function (t, e) {
                    return "image" === t.type && "close";
                },
                // touch: true,
                touch: false,
                // arrows: false,
            },
            afterShow: function (instance, current) {
                if (!mediaFullDesktop.matches) {
                    jQuery(".fancybox-navigation button").on('click', function (event) {
                        event.preventDefault();
                        jQuery(this).prop('disabled', true);
                    });
                }
            }
        });
    }
}

function manniello_menu_fixed() {

    ;(function ($) {
        var check_slideshow = jQuery('.slideshow-hotel');
        var check_height_slidershow;
        var checkRestaurant = jQuery('.page-template-template-restaurants-archive');
        // var parallaxRender = jQuery('.parallax-mirror');
        var parallaxSliderRender = jQuery('.parallax-slider');


        function add_classes_js() {
            check_height_slidershow = check_slideshow.height();
            if ($(window).width() + 12 > 992) {
                if (jQuery(this).scrollTop() >= check_height_slidershow) {
                    jQuery('body').addClass('menu-white');
                    if (parallaxSliderRender.length && !is_mobile) {
                        parallaxSliderRender.addClass('will-change-speed');
                        // The class before is not rendered. cant use parallaxRender
                        jQuery('.parallax-mirror').addClass('will-change-speed');
                    }
                } else {
                    jQuery('body').removeClass('menu-white');
                    if (parallaxSliderRender.length && !is_mobile) {
                        parallaxSliderRender.removeClass('will-change-speed');
                        jQuery('.parallax-mirror').removeClass('will-change-speed');
                    }
                }
            } else if ($(window).width() + 12 < 992) {
                if (jQuery(this).scrollTop() >= check_height_slidershow) {
                    jQuery('body').addClass('mobile-style').removeClass('menu-white');
                } else {
                    jQuery('body').removeClass('mobile-style');
                }
            }
        }

        // When clicking inside Restaurant with page go to Anchor ID and open the menu
        if (checkRestaurant.length) {
            function restaurantAnchorMenu() {
                restaurantAnchorMenu = function () {
                }; // Kill the function
                if (window.scrollY > 1500 && $(window).width() + 12 > 992) {
                    setTimeout(function () {
                        jQuery('body').addClass('menu-white');
                        // console.log('Anchor ID Menu Loaded')
                    }, 800)
                }
            }

            restaurantAnchorMenu();
        }

        // Only run when slideshow is on
        if (check_slideshow.length) {

            // Improve the performance with Debounce and Throttle
            if (!is_mobile) {
                window.addEventListener('scroll', manniello_throttle(add_classes_js, 150));
            } else {
                // Avoid menu blinking mobile
                window.addEventListener('scroll', manniello_throttle(add_classes_js, 0));
            }


            function resizeFunctionClasses() {
                add_classes_js();
                // Run for parallax update
                var container_menu = jQuery('.container-menu');
                if (container_menu.length) {
                    // With the menu scrolled the parallax get another height. It fix that
                    container_menu.hide();
                    setTimeout(function () {
                        container_menu.show();
                    }, 50);
                }
                jQuery(window).trigger('resize').trigger('scroll')
            }

            if (!is_mobile) {
                window.addEventListener('resize', manniello_debounce(resizeFunctionClasses, 200));
            }


        }

    })(jQuery);
}

function manniello_debounce(func, time) {
    var time = time || 100; // 100 Default if no param is sent
    var timer;
    return function (event) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, time, event);
    };
}

function manniello_throttle(callback, limit) {
    var waiting = false;
    return function () {
        if (!waiting) {
            callback.call();
            waiting = true;
            setTimeout(function () {
                waiting = false;
            }, limit);
        }
    }
}

function mannielo_menu_second() {
    ;(function ($) {
        var menu_secondmenu = jQuery('.menu-secondmenu');
        if (menu_secondmenu.length) {
            var thisScroll = 0, lastScroll = 0;
            // The menu doesn't exists at this point
            // var menu_height = jQuery('.menu-white .menu-highlights').height();
            var menu_height = '85';
            var newNav = menu_secondmenu.clone().hide();
            newNav.attr('id', 'stickynav');
            newNav.css('top', menu_height + 'px');
            $('body').append(newNav);

            function scrolling_second_menu() {
                // console.log('Checking Scrolling')
                thisScroll = $(window).scrollTop();
                if (menu_secondmenu.offset().top - thisScroll <= menu_height && thisScroll > lastScroll) {
                    newNav.show();
                    manniello_smooth_scroll_fix();
                } else if (menu_secondmenu.offset().top - $(window).scrollTop() > menu_height && thisScroll < lastScroll) {
                    // $('#stickynav').remove();
                    newNav.hide();
                    // Remove all the old hovers if it have.
                    $('.wrapper-secondmenu li a').removeClass('active');
                }
                lastScroll = thisScroll;
            }


            // To 0 to avoid when clicking on the anchor link and the menu disappear
            window.addEventListener('scroll', manniello_throttle(scrolling_second_menu, 0));
        }
    })(jQuery);
}

function manniello_secondmenu_slider() {
    ;(function ($) {
        if ($('body').hasClass('page-template-template-landing-page-arabic')) {
            var menu_secondmenu = jQuery('nav .wrapper-secondmenu');
            var menu_secondmenu_width = menu_secondmenu.outerWidth(true);
            var items = menu_secondmenu.children();
            var items_width = 0;
            items.each(function () {
                items_width = items_width + $(this).outerWidth(true);
            });
            if ((items_width > menu_secondmenu_width) ) {
                menu_secondmenu.addClass('slider_is_visible');
                menu_secondmenu.not('.slick-initialized').slick({
                    infinite: false,
                    variableWidth: true,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    arrows: true,
                    nextArrow: '<a class="slick-arrows next-hor" href="javascript:void(0)"></a>',
                    prevArrow: '<a class="slick-arrows prev-hor" href="javascript:void(0)"></a>',
                    rtl: $('html').attr('dir') == 'rtl'
                });
            } else {
                menu_secondmenu.removeClass('slider_is_visible');
                if (menu_secondmenu.hasClass('slick-initialized')) {
                    menu_secondmenu.slick('unslick');
                }
            }
        }
    })(jQuery);
}

function manniello_fancybox_popup() {
    // Ultra speed, Fancybox popup with Cookie for 1 day
    // Fancybox loaded with ewdelayedscript
    var fancy_popup = $('#popup-box');
    if (fancy_popup.length) {
        // 2 Hours. Then use the expireCookiesHours in expires: expireCookiesHours
        // var expireCookiesHours = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
        // 15 minutes
        // var expireCookiesMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
        var active = Cookies.get('active');
        if (active === 'yes') {
            // Change to 'no' if you want to disable the Cookie and test
            return false;
        } else {
            setTimeout(function () {
                if (typeof jQuery.fancybox != 'undefined') {

                    fancy_popup.fancybox({
                        beforeLoad: function () {
                            jQuery('#popup-box .container-medium').css('display', 'flex');
                        },
                        animationEffect: "zoom-in-out",
                        touch: false,
                        scrolling: 'auto',
                    })

                    jQuery('#popup-box').trigger('click');
                    // With beforeshow didn't work
                    jQuery('.fancybox-content').css({
                        'background': 'transparent',
                        'padding': 0,
                    });
                    // Autoclose function
                    // setTimeout(function(){
                    //     $.fancybox.close();
                    // }, 5000)
                }
            }, 6000);
        }

        if (document.location.protocol === 'http:') {
            Cookies.set('active', 'yes', {
                sameSite: 'lax', // Change to none when is HTTPS
                secure: false, // Active when is HTTPS
                expires: 1 // The number of days cookie will be effective
            });
        } else {
            Cookies.set('active', 'yes', {
                sameSite: 'strict', // Change to none when is HTTPS
                secure: true, // Active when is HTTPS
                expires: 1 // The number of days cookie will be effective
            });
        }


    }
}

function manniello_menu_open() {
    var menu_click = document.querySelector(".menu-highlights .menu-hamburger");
    var menu_block = document.querySelector(".menu-full");
    var menu_cross = document.querySelector(".menu-cross");
    var coverImage = document.querySelector('.menu-full .cover-image');

    if (menu_click != null && menu_cross != null) {
        // Add the will-change on hover the hamburger
        menu_click.addEventListener("mouseenter", addSpeedChange, false);
        menu_click.addEventListener("click", fullMenuOpen);
        menu_cross.addEventListener("click", fullMenuClose);
    }

    function fullMenuOpen() {
        menu_block.classList.toggle("active");
        coverImage.classList.toggle('showanimation');
        colorBar();
        manniello_disableScrolling();
        if (document.querySelector('body').classList.contains('home')) {
            // Load Icons on open. Already loaded with ew, check.
            loadAssetsFonts()
        }
    }

    function addSpeedChange() {
        // console.log('Animation started');
        coverImage.style.willChange = 'transform';
        coverImage.addEventListener('animationend', animationEndCallback);
    }

    var animationEndCallback = () => {
        // Remove the will-change transform when the animation is finished
        coverImage.style.willChange = 'auto';
        // console.log('Animation ended fire');
    }

    function colorBar() {
        var rootFirefox = document.querySelector(':root');
        jQuery('body').toggleClass('hide-native-scrollbar');
        rootFirefox.classList.toggle('opened');
    }

    function fullMenuClose() {
        menu_block.classList.toggle("active");
        colorBar();
        manniello_enableScrolling();
        document.querySelector('.menu-full .cover-image').classList.toggle('showanimation');
    }

    if (menu_block != null) {
        document.querySelectorAll(".menu-hamburger-mobile").forEach(function (box_menu) {
            box_menu.addEventListener("click", fullMenuOpen);
            box_menu.addEventListener("pointerenter", addSpeedChange);
        })
    }

}

// LazyLoad with IntersectionObserver
function manniello_lazy_load_img(images_sliders) {
    var images = (images_sliders != null) ? images_sliders : Array.from(document.querySelectorAll('.lazy_img'))

    if (images != null) {
        if ('IntersectionObserver' in window) {
            setupIntersectionObserver(images);
        } else {
            loadImages(images);
        }
    }

    function setupIntersectionObserver(images) {
        var options = {
            rootMargin: '50px 50px 50px 50px',
            threshold: 0
        };

        var observer = new IntersectionObserver(onIntersection, options);
        images.forEach(image => observer.observe(image));
    }

    function onIntersection(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
                observer.unobserve(entry.target);
                loadImage(entry.target);
            }
        });
    }

    function loadImage(image) {
        // Delete the check src for the src aspect ratio blank (solved with loaded)
        if (!image.classList.contains('loaded')) {
            image.setAttribute('src', image.getAttribute('data-src'));
            image.classList.add('loaded');
            // Problems with empty blank sliders that gives null to src (solved with loaded)
            // image.onload = () => image.removeAttribute('data-src');
        }
    }

    function loadImages(images) {
        images.forEach(loadImage);
    }
}

// LazyLoad Background with IntersectionObserver
function manniello_lazy_load_bg_img() {
    var images = Array.from(document.querySelectorAll('.lazy_bg_img'));

    if (images != null) {
        if ('IntersectionObserver' in window) {
            setupIntersectionObserver(images);
        } else {
            loadImages(images);
        }
    }

    function setupIntersectionObserver(images) {
        var options = {
            root: null,
            rootMargin: '0px',
            //threshold size in % of the image that need to read to start the lazy loading
            threshold: 0.05
        };

        var observer = new IntersectionObserver(onIntersection, options);
        images.forEach(image => observer.observe(image));
    }

    function onIntersection(entries, observer) {
        entries.forEach((entry) => {
            // size in % of the image that need to read to start the lazy loading
            if (entry.intersectionRatio >= 0.05) {
                observer.unobserve(entry.target);
                loadImage(entry.target);
            }
        });
    }

    function loadImage(image) {
        image.setAttribute('style', image.getAttribute('data-style'));
        image.removeAttribute('data-style');
    }

    function loadImages(images) {
        images.forEach(loadImage);
    }
}

function manniello_instagram_lazy() {
    // Loaded in Ultra-Speed. Second Option:
    ;(function ($) {
        var instagram_feeds_target_two = document.getElementById('container-instagram');
        if (instagram_feeds_target_two != null) {

            function loadInstagramLazy() {
                if (website_url.includes('ms3.demo-site.it')) {
                    // console.log('Running on Development server');
                    // loadCSS_fast(website_url + '/wp-content/plugins/instagram-feed-pro/css/sbi-styles.min.css', 'instagram_css')
                    loadCSS_fast(website_url + '/wp-content/plugins/instagram-feed-pro/css/sb-instagram.min.css', 'instagram_css')
                } else {
                    loadCSS_fast(website_url + '/wp-content/plugins/instagram-feed-pro/css/sb-instagram.min.css', 'instagram_css')
                }
                $('#container-instagram').append(JSON.parse($('#instagram_data').text()));

                if (typeof sbi_init != 'undefined') {
                    sbi_init();
                }
                console.log('Instagram Widget Intersected');
            }

            if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
                // var instagram_feeds = document.querySelector('.targetInstagram');

                var options = {
                    // root: null,
                    rootMargin: '600px 0px 600px 0px',
                    //threshold size in % of the image that need to read to start the lazy loading
                    threshold: 0.01
                };

                var observer = new IntersectionObserver(
                    function (entries, observer) {
                        var isIntersecting = typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0;
                        if (isIntersecting) {
                            loadInstagramLazy();
                            // if (instagram_feeds != null) {
                            //     observer.unobserve(instagram_feeds);
                            // }
                            observer.unobserve(instagram_feeds_target_two);
                        }
                    },
                    options
                );

                // Observer with two points of observer. Top and Bottom
                // if (instagram_feeds != null) {
                //     observer.observe(instagram_feeds);
                // }
                // let runInstagram = document.querySelector('.sbi_item');
                // if (runInstagram == null) {
                observer.observe(instagram_feeds_target_two);
                // }

            } else {
                loadInstagramLazy()
            }

        }
    })(jQuery);
}

function manniello_newsletter_lazy() {
    // Loaded in Ultra-Speed. Second Option:
    ;(function ($) {
        var mailup_target_two = document.getElementById('mailup_intersection');
        if (mailup_target_two != null) {

            function loadMailUpLazy() {
                $('.footer-newsletter-right').append(JSON.parse($('#widget_mailup_data').text()));
                console.log('MailUp Widget Intersected');
            }

            if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
                // var mailup_feeds = document.querySelector('.targetInstagram');

                var options = {
                    // root: null,
                    rootMargin: '600px 0px 600px 0px',
                    //threshold size in % of the image that need to read to start the lazy loading
                    threshold: 0.01
                };

                var observer = new IntersectionObserver(
                    function (entries, observer) {
                        var isIntersecting = typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0;
                        if (isIntersecting) {
                            loadMailUpLazy();
                            // if (mailup_feeds != null) {
                            //     observer.unobserve(mailup_feeds);
                            // }
                            observer.unobserve(mailup_target_two);
                        }
                    },
                    options
                );

                // Observer with two points of observer. Top and Bottom
                // if (mailup_feeds != null) {
                //     observer.observe(mailup_feeds);
                // }
                observer.observe(mailup_target_two);


            } else {
                loadMailUpLazy()
            }

        }
    })(jQuery);
}

// With Intersection Observer Scroll to Top
function manniello_scroll_top() {
    // If no Intersection in Windows don't load
    if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
        var target = document.querySelector("#footer-mainlogo");

        var scrollToTopBtn = document.querySelector(".scrollToTopBtn");

        function scrollToTop() {
            jQuery("html, body").animate({scrollTop: 0}, 1000)
            var second_menu_sticky_remove = jQuery('#stickynav');
            if (second_menu_sticky_remove.length) {
                // Fix when click Scroll Top remove the Sticky Second menu Restaurant
                second_menu_sticky_remove.hide();
            }
        }

        scrollToTopBtn.addEventListener("click", scrollToTop);

        var options = {
            // root: null,
            rootMargin: '0px 0px 200px 0px',
            //threshold size in % of the image that need to read to start the lazy loading
            threshold: 0
        };

        var observer = new IntersectionObserver(
            function (entries, observer) {
                var isIntersecting = typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0;
                if (isIntersecting) {
                    // Show button
                    scrollToTopBtn.classList.add("show");
                } else {
                    // Hide button
                    scrollToTopBtn.classList.remove("show");
                }
            },
            options
        );

        observer.observe(target);
    }
}

function manniello_smooth_scroll_fix() {
    ;(function ($) {
        var a_main_links = $('a.main-smooth-links');
        if (a_main_links.length) {
            a_main_links.click(function (e) {
                e.preventDefault();
                var targetHref = '#' + $(this).attr('data-scroll');
                goToLink(targetHref);
            });
        }


        var a_slideshow_links = $('a.main-smooth-links-slideshow');
        if (a_slideshow_links.length) {
            a_slideshow_links.click(function (e) {
                e.preventDefault();
                var targetHref = $(this).attr('href');
                goToLink(targetHref);
            });
        }

        function goToLink(targetLink) {
            var top = ($(targetLink).offset() || {"top": NaN}).top;
            var movementTop = jQuery('.page-template-template-landing-page') ? -20 : 80;
            if (isNaN(top)) {
                console.log('Link doesn\'t exists')
            } else {
                $('html, body').stop().animate({
                    scrollTop: $(targetLink).offset().top - movementTop
                }, 800);
            }
        }

        var a_anchor_links = $('a.smooth-links');
        if (a_anchor_links.length) {
            var second_menu = $('.menu-secondmenu');
            var height_second_menu = (second_menu.outerHeight() > 0) ? second_menu.outerHeight() : '0'
            var headerHeight = $('.menu-highlights').outerHeight() + height_second_menu
            a_anchor_links.click(function (e) {
                e.preventDefault();
                // console.log('Click');
                // Avoid changing color when scrolling on click
                $(document).off("scroll", onScroll);

                setTimeout(function () {
                    $(document).on("scroll", onScroll);
                    // Leave the scroll to change the active
                }, 3000);

                var currentNodeActive = $(this).attr('data-scroll');
                $('#stickynav ul li a').removeClass('active');
                var target_clicked = $('#stickynav ul li a[data-scroll$="' + currentNodeActive + '"]')
                setTimeout(function () {
                    //     $(this).addClass('active');
                    target_clicked.addClass('active');
                }, 500);

                // $('#stickynav ul li a').removeClass('active');

                var targetHref = '#' + currentNodeActive;
                $('html, body').stop().animate({
                    scrollTop: $(targetHref).offset().top - headerHeight
                }, 600, function () {
                    // Callback after animation
                    // Must change focus!
                    target_clicked.focus();
                    // if (target_clicked.is(":focus")) { // Checking if the target was focused
                    //     return false;
                    // } else {
                    //     target_clicked.attr('tabindex', '-1');
                    //     target_clicked.focus();
                    // }

                });

            });

            jQuery(document).on("scroll", onScroll);

            var currentNode = null;

            // $('#stickynav ul li a').removeClass('active');

            function onScroll() {
                $('.active-restaurant-block').each(function () {
                    if ($(this).attr('id') != undefined && $(window).scrollTop() >= ($(this).offset().top - 300)) {
                        currentNode = $(this).attr('id');
                        $('#stickynav ul li a').removeClass('active');
                        $('#stickynav ul li a[data-scroll$="' + currentNode + '"]').addClass('active');
                    }
                });
            };

        }


    })(jQuery);
}

function manniello_filter_rooms() {
    ;(function ($) {
        var filterselect = $('.filters-rooms');
        if (filterselect.length) {

            var data_filters = $('#data-filters-rooms');
            var loader_filter = $('.loader-filters')

            var $grid = filterselect.isotope({
                itemSelector: '.item-room-h',
                layoutMode: 'fitRows',
                // Leave the time for some spinner
                transitionDuration: '0.5s',
                transformsEnabled: false,
            });


            data_filters.on('click', 'a', function () {
                filterselect.css('visibility', 'hidden');
                loader_filter.css('display', 'inline-block');
                data_filters.find('a.button').removeClass('is-checked');
                $(this).addClass('is-checked');
                // Don't move this event order, the Isotope needs to get the filter without this class
                filterselect.find('.item-room-h').removeClass('full-room-h');
                var filterValue = $(this).attr('data-filter');
                $grid.isotope({filter: filterValue});
            });

            $grid.on('arrangeComplete', function (event, filteredItems) {
                var result_even = (filteredItems.length % 2 === 0) ? "even" : "odd";
                if (result_even === 'odd') {
                    var last_item = filteredItems[filteredItems.length - 1];
                    last_item.element.classList.add('full-room-h');
                }
                $grid.isotope('layout');
            });

            $grid.on('layoutComplete', function (event, laidOutItems) {
                    // Avoid first loading movement with the setTimeout
                    setTimeout(function () {
                        loader_filter.css('display', 'none');
                        filterselect.css('visibility', 'visible');
                    }, 400);
                }
            );

        }
    })(jQuery);
}

function manniello_filter_offers() {
    ;(function ($) {
        var filter_ofers_select = $('.offers-block');
        if (filter_ofers_select.length) {

            var data_filters = $('#data-filters-offers');
            var loader_filter_offers = $('.loader-filters')

            var $grid = filter_ofers_select.isotope({
                itemSelector: '.offers-item',
                layoutMode: 'fitRows',
                // Leave the time for some spinner. With the timeout in layoutcomplete is better
                // Added the time in the LayoutComplete, is better. Fix the random full movement block
                transitionDuration: '0s',
                transformsEnabled: false,
            });


            data_filters.on('click', 'a', function () {
                filter_ofers_select.css('visibility', 'hidden');
                loader_filter_offers.css('display', 'inline-block');
                data_filters.find('a.button').removeClass('is-checked');
                $(this).addClass('is-checked');
                // Don't move this event order, the Isotope needs to get the filter without this class
                filter_ofers_select.find('.offers-item').removeClass('offer-max-width');
                var filterValue = $(this).attr('data-filter');
                $grid.isotope({filter: filterValue});
            });

            $grid.on('arrangeComplete', function (event, filteredItems) {
                var result_even = (filteredItems.length % 2 === 0) ? "even" : "odd";
                if (result_even === 'odd') {
                    var last_item = filteredItems[filteredItems.length - 1];
                    last_item.element.classList.add('offer-max-width');
                }
                $grid.isotope('layout');
            });

            $grid.on('layoutComplete', function (event, laidOutItems) {
                    // Avoid first loading movement with the setTimeout
                    setTimeout(function () {
                        loader_filter_offers.css('display', 'none');
                        filter_ofers_select.css('visibility', 'visible');
                    }, 600);
                }
            );

        }
    })(jQuery);
}

function manniello_filter_group_offers() {
    ;(function ($) {
        var filter_ofers_select = $('.offers-block');
        if (filter_ofers_select.length) {

            // Init Isotope
            var filter_hotel = $('#data-filters-hotels .button');
            var filter_category = $('#data-filters-categories .button');
            var grid = filter_ofers_select;
            grid.isotope({
                itemSelector: '.offers-item',
                layoutMode: 'fitRows',
                transitionDuration: '0.5s',
                transformsEnabled: false,
            });


            var message_error_div = $('.message-jobs-div');

            filter_hotel.on('click', function () {
                message_error_div.hide();
                filter_hotel.removeClass('is-checked');
                $(this).addClass('is-checked');
                var filterValue = $(this).attr('data-filter');
                var filterValueCat = $('#data-filters-categories .button.is-checked').attr('data-filter');
                getValuesOffers(filterValue, filterValueCat);
            });

            filter_category.on('click', function () {
                message_error_div.hide();
                filter_category.removeClass('is-checked');
                $(this).addClass('is-checked');
                var filterValue = $(this).attr('data-filter');
                var filterValueCat = $('#data-filters-hotels .button.is-checked').attr('data-filter');
                getValuesOffers(filterValue, filterValueCat);

            });

            function getValuesOffers(first_value, second_value) {
                // var value_filter = first_value.concat(second_value);
                if (!is_mobile) {
                    jQuery("html, body").stop().animate({scrollTop: 0}, 800);
                }
                var value = first_value + second_value;
                // var value = (filter_hotel === '*' && filter_category === '*') ? "*" : value_filter;

                // Message nothing found
                grid.on('arrangeComplete', function (event, filteredItems) {
                    var resultCount = filteredItems.length;
                    if (resultCount === 0 && value != '*') {
                        message_error_div.show();
                        message_error_div.css('opacity', '1');
                    } else {
                        message_error_div.hide();
                    }
                });

                // Get filter value from option value
                grid.isotope({filter: value});
            }


        }
    })(jQuery);
}

function manniello_filter_photogallery() {
    ;(function ($) {
        var filter_photogallery_select = $('.photogallery-block');
        if (filter_photogallery_select.length) {

            var data_filters = $('#data-filters-images');

            var $grid = filter_photogallery_select.isotope({
                itemSelector: '.item-photogallery',
                layoutMode: 'fitRows',
                // transitionDuration: '0.5s',
                transitionDuration: '0',
                transformsEnabled: false,
            });

            var filterValuePhoto;

            // Let the animation finish
            $grid.on('arrangeComplete', function (event, filteredItems) {
                var itemDisplaying = jQuery(".img-wrap-photogallery:not([style*='display: none']) > a")
                itemDisplaying.attr('data-fancybox', 'new-photogallery').addClass('opacity-animation-photo');
            });


            data_filters.on('click', 'a', function () {
                data_filters.find('a.button').removeClass('is-checked');

                // Reset all the data-fancybox
                var itemDisplaying = jQuery(".img-wrap-photogallery a");
                itemDisplaying.attr('data-fancybox', 'gallery-photogallery');
                itemDisplaying.removeClass('opacity-animation-photo');

                $(this).addClass('is-checked');
                filterValuePhoto = $(this).attr('data-filter');
                $grid.isotope({filter: filterValuePhoto});
            })


        }
    })(jQuery);
}

function manniello_filter_by_select(grid_block, filterselect, item_block) {
    ;(function ($) {
        if (grid_block.length) {
            var $grid = grid_block.isotope({
                itemSelector: item_block,
                layoutMode: 'fitRows',
                transitionDuration: '0.6s',
                transformsEnabled: false,
            });

            // Check if is Photogallery and then put a specific data-fancybox value for the showed elements
            if (item_block === '.item-photogallery') {
                $grid.on('arrangeComplete', function (event, filteredItems) {
                    var itemDisplaying = jQuery(".img-wrap-photogallery:not([style*='display: none']) > a")
                    itemDisplaying.attr('data-fancybox', 'new-photogallery');
                });
            }

            // Bind filter on select change
            filterselect.on('change', function () {
                var value = $(this).val();
                // Get filter value from option value

                // Reset the value of data-fancybox if is Photogallery
                if (item_block === '.item-photogallery') {
                    var itemDisplaying = jQuery(".img-wrap-photogallery a");
                    itemDisplaying.attr('data-fancybox', 'gallery-photogallery');
                }

                $grid.isotope({filter: value});
            });
        }

    })(jQuery);
}

window.loadCSS_fast = function (link_style, id_style = 'loadcss_fast', before) {
    // Remember to send the ID of the CSS to make the validation
    // Check with ID
    var check_exist_css = document.getElementById(id_style);
    if (check_exist_css == null) {
        // var head = document.head;
        var doc = window.document;
        var link = document.createElement("link");

        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = link_style;
        link.id = id_style;

        // head.appendChild(link);
        // The insertBefore works better

        // If before is send insert before the ID
        // If before is empty insert before the head close
        var ref;
        if (before) {
            ref = document.getElementById(before);
        } else {
            var refs = (doc.getElementsByTagName("head")[0] || doc.body).childNodes;
            ref = refs[refs.length - 1];
        }
        ref.parentNode.insertBefore(link, (before ? ref : ref.nextSibling));
        console.log('Asset loaded ' + id_style);

    }
}


if (document.querySelector('body').classList.contains('home') != null) {
    loadCSS_fast('https://p.typekit.net/p.css?s=1&k=fhk0kbb&ht=tk&f=10884.15586.42534.42535&a=1537234&app=typekit&e=css', 'fonts_typekit');
    loadCSS_fast(theme_url + '/css/fonts_preload.css', 'fonts_preloaded');
}

function manniello_icons_lazy() {

    // Check if CSS exists
    window.loadAssetsFonts = function () {
        loadCSS_fast('//staticaws.fbwebprogram.com/FBServicesWebfonts/css/services_webfont.css', 'icons_css');
    }
}

function manniello_menu_food_movement_fix() {
    // Fix when moves (white blink) from 4 menu columns to another menu columns, the first time
    var menu_list = jQuery('.fbm-menu_list');
    if (menu_list.length) {
        jQuery('.fbm-menu_tabs').on('click', 'a', function () {
            menu_list.css('visibility', 'hidden').addClass('show');
            setTimeout(function () {
                menu_list.css('visibility', 'visible');
            }, 200);
            setTimeout(function () {
                menu_list.removeClass('show');
            }, 810);
        });
    }
}

function manniello_web_sdk_offers() {
    ;(function ($) {
        if (typeof $fbsdk != 'undefined') {
            if (jQuery('div[data-websdk]').length > 0) {
                $fbsdk.Config.baseHost = 'websdk.fastbooking-services.com';
                jQuery('div[data-websdk]').each(function () {
                    var container = $(this),
                        model_name = container.data('websdk'),
                        id = container.attr('id'),
                        custom_template = container.data('websdk-template'),
                        template_id, config, model, template, html;
                    template_id = custom_template ? custom_template : '#' + id + '_template';
                    template = $(template_id).text();
                    if (typeof websdk_config !== 'undefined' && typeof websdk_config[id] !== 'undefined') {
                        config = websdk_config[id];
                        $fbsdk.baseHost = config.baseHost;

                        model = new $fbsdk[model_name](config.params);
                        model.setAuthToken(config._authCode);
                        model.load(function (error) {
                            var data, i;
                            if (!error) {
                                data = JSON.parse(JSON.stringify(this.getRawData()));

                                if (data.rates !== 'undefined') {
                                    for (var i = 0; i < data.rates.length; i++) {
                                        // Price Int
                                        data.rates[i].quotation.totalPrice = Math.floor(data.rates[i].quotation.totalPrice);
                                        // Trim description
                                        data.rates[i].rate.shortdescription = data.rates[i].rate.plain_description.substr(0, 150) + '...';
                                    }
                                }

                                // Limit numbers of offers to display
                                // if (typeof config.number !== 'undefined' && config.number <= data.rates.length) {
                                //     data.rates.length = parseInt(config.number, 10);
                                // }

                                html = Mustache.render(template, data);
                                container.html(html);

                                // Wait the websdk offers to show the block
                                var special_offers = jQuery('.single-rooms #room_offers');
                                if (special_offers.length) {
                                    if (special_offers.children().length > 1) {
                                        jQuery('.single-rooms #block-slider-half').css('display', 'block');
                                    }
                                }

                                // Avoid calling twice the Slick Carousel for two blocks in same page.
                                // Also with setTimeout 200 works. It gives some time to slick to start and don't
                                // Run twice, with only the not('.slick-initialized') don't solve the problem
                                if (container.hasClass('slider-one-call')) {
                                    manniello_web_sdk_carousel();
                                }


                            } else {
                                // Error
                                container.fadeOut();
                            }
                        });
                    }
                });
            }
        } else {
            console.log('fbsdk is Undefined, add more time')
        }
    })(jQuery);
}

function manniello_web_sdk_carousel() {
    var slider_room_half_check = jQuery('.room-slider-half').not('.slick-initialized');
    if (slider_room_half_check.length > 0) {
        var options = {
            rows: 0,
            slidesToShow: 2,
            slidesToScroll: 1,
            // The half is solved with padding 0 20%..
            nextArrow: '<a class="slick-arrows next-hor up-slider" href="javascript:void(0)"></a>',
            prevArrow: '<a class="slick-arrows prev-hor up-slider" href="javascript:void(0)"></a>',
            responsive: [
                {
                    breakpoint: 761,
                    settings: {
                        slidesToShow: 1
                    }
                }]
        };
        slider_room_half_check.on('init', function (event, slick, direction) {
            var images = Array.from(document.querySelectorAll('.room-slider-half .lazy_img'));
            manniello_lazy_load_img(images);
        }).slick(options);
        // slider_room_half_check.slick(options);
    }
}


function manniello_dynamic_calendar() {
    var btns = jQuery('.dyna-calendar');

    if (btns.length && jQuery('body.page-template-template-landing-page').length < 1) {

        var calendar_wrapper = jQuery('#dyncal_wrapper');
        var rootFirefox = document.querySelector(':root');
        btns.click(function () {
            if (!calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.addClass('visible');
                if (!is_mobile) {
                    manniello_disableMouseScroll();
                    // jQuery('html').toggleClass('no-scroll');
                    jQuery('body').toggleClass('hide-native-scrollbar');
                    rootFirefox.classList.toggle('opened');
                }
            }
        });

        jQuery('.close_calendar').click(function () {
            if (calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.removeClass('visible');

                // On close reset the Dynamic Calendar
                // To don't save the info if I put some dates
                loadFBWidgets();

                if (!is_mobile) {
                    manniello_enableScroll();
                    // jQuery('html').toggleClass('no-scroll');
                    jQuery('body').toggleClass('hide-native-scrollbar');
                    rootFirefox.classList.toggle('opened');
                }
            }
        });
    }
}

function manniello_calendar_all() {
    var btns = jQuery('#calendar-all-button');

    if (btns.length) {

        var calendar_wrapper = jQuery('#calendar_container');
        var rootFirefox = document.querySelector(':root');
        btns.click(function () {
            if (!calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.addClass('visible');
                if (!is_mobile) {
                    manniello_disableMouseScroll();
                    jQuery('body').toggleClass('hide-native-scrollbar');
                    rootFirefox.classList.toggle('opened');
                }
            }
        });

        jQuery('.close_calendar').click(function () {
            if (calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.removeClass('visible');

                if (!is_mobile) {
                    manniello_enableScroll();
                    jQuery('body').toggleClass('hide-native-scrollbar');
                    rootFirefox.classList.toggle('opened');
                }
            }
        });
    }
}

function manniello_group_dynamic() {
    var btns = jQuery('.dyna-calendar');
    var bookNowHotels = jQuery('.book-now-hotels');
    if (btns.length && bookNowHotels.length) {
        bookNowHotels.on("click", function () {
            var widget_config_holder = jQuery('#fb-widget-config');
            var dataOrder = jQuery(this).attr('data-order');
            btns.trigger('click');
            if (widget_config_holder.length && dataOrder.length) {
                console.log('Dyn Calendar Trigger')
                try {
                    // Read config from the <script>
                    var widget_config = JSON.parse(widget_config_holder.text());
                    if (widget_config
                        && typeof widget_config.params !== 'undefined'
                        && widget_config.params.length > 0
                        && typeof widget_config.params[0].calendar !== 'undefined') {


                        // The default initial Dyn Cal. is the params[0]
                        // Is changing the default with the clicked one ( params[dataOrder] )
                        // The same order of Hotels are the same order of the Dynamic Cal. Select
                        if (dataOrder !== '0') {
                            var saveIntialParam = widget_config.params[0];
                            widget_config.params[0] = widget_config.params[dataOrder];
                            widget_config.params[dataOrder] = saveIntialParam;
                        }
                        // console.log(widget_config);
                        jQuery('#fb-widget-config').text(JSON.stringify(widget_config));
                        if (typeof window.loadFBWidgets === 'function') {
                            loadFBWidgets();
                        } else {
                            window.console && console.log('Trying to reload widgets before they are available')
                        }
                    }
                } catch (e) {
                    window.console && console.log('Failed parsing widget configuration');
                    // Fail silently
                }
            }

        });


    }
}

function manniello_booking_group() {
    loadjs([website_url + '/wp-includes/js/jquery/ui/core.min.js',
            website_url + '/wp-includes/js/jquery/ui/datepicker.min.js'],
        'dependencyBooking', {
            async: false,
        });

    var loadFinal = [theme_url + '/js/booking-noprices-all.min.js'];
    // Add MultiLang Booking noprices All. The Gravity can add it
    var dateLinki18n = fbc_url + '/js/i18n/jquery.ui.datepicker-' + language + '.js';
    if (!check_if_exists_js(dateLinki18n)) {
        // Put first, don't change order
        loadFinal.unshift(dateLinki18n)
    }

    loadjs.ready('dependencyBooking', {
        success: function () {
            // Don't change the order. ui-datepicker-trigger needs to be in the DOM
            loadjs(loadFinal)
            console.log('Booking All Hotels assets Loaded');
            loadjs.done();
        }
    });
}

function manniello_dynamic_lazy() {

    var target_header_book = document.querySelector('.dyna-calendar');
    var calendar_block = document.querySelector('.dyncal_box');

    // Add listener for the Group dynamic calendar buttons
    var bookNowHotels = jQuery('.book-now-hotels');

    if (target_header_book != null) {
        // Mouseover for Desktop and Click for Mobile
        target_header_book.addEventListener('mouseenter', loadDynamicLazy, false);
        target_header_book.addEventListener('click', loadDynamicLazy, false);
        window.addEventListener('touchmove', loadDynamicLazy, false);
        if (bookNowHotels.length) {
            bookNowHotels.on('mouseenter click', loadDynamicLazy);
        }

        function loadDynamicLazy() {
            // Load the app.css dynamic in assets, the external was slow (randomly)
            // loadCSS_fast(theme_url + '/assets/app-dynamic.css', 'dynamic_css')
            // The app.css has changed and make errors
            loadCSS_fast('//websdk.fastbooking-services.com/widgets/app.css', 'dynamic_css');
            // Fix showing the old style while loading. Add dynca_box none in Style.css
            setTimeout(function () {
                if (calendar_block != null) {
                    // Wait to load the CSS app.css. It only add the time once.. just to load
                    // It resolve the "problem" when you try to open it very fast when the page is loading
                    calendar_block.style.display = 'block';
                }
            }, 300)
            target_header_book.removeEventListener('mouseenter', loadDynamicLazy);
            target_header_book.removeEventListener('click', loadDynamicLazy);
            window.removeEventListener('touchmove', loadDynamicLazy);
            if (bookNowHotels.length) {
                bookNowHotels.off('mouseenter');
            }
            clearTimeout(ewDelayDynamic);
        }

        var ewDelayDynamic = setTimeout(function () {
            loadDynamicLazy();
        }, 8000);

    }
}


// Called in manniello-single-custom.php
function manniello_social_login() {
    if (!is_mobile) {
        var rootFirefox = document.querySelector(':root');
        // jQuery('html').toggleClass('no-scroll');
        manniello_disableMouseScroll();
        // jQuery('body').toggleClass('hide-native-scrollbar');
        rootFirefox.classList.toggle('opened');
    }

    if (typeof pop !== 'undefined') {
        return pop('fbga-login-overlay');
    }
}

function manniello_close_login() {
    /* Remove the original onclick */
    setTimeout(function () {
        var cross_social = jQuery('#close-ga');
        var rootFirefox = document.querySelector(':root');
        cross_social.removeAttr("onclick");
        cross_social.prop("onclick", null);
        cross_social.on('click', function () {
            // The Social needs space to scroll
            jQuery('body').css('overflow', 'auto');
            if (!is_mobile) {
                manniello_enableScroll();
                // Bug, sometimes didn't change the class
                // jQuery('html').toggleClass('no-scroll');
                // jQuery('body').toggleClass('hide-native-scrollbar');
                rootFirefox.classList.toggle('opened');
            }
            jQuery('#fbga-login-overlay').fadeOut("slow");
        });
    }, 3000);
}

// Called in manniello-single-custom.php
function manniello_social_logout() {
    if (typeof logout_onclick !== 'undefined') {
        window.logout_onclick();
    }
}


// Check if Script exists
function check_if_exists_js(src) {
    var scripts = document.getElementsByTagName("script");
    // Check without script ID
    for (var i = 0; i < scripts.length; i++)
        if (scripts[i].getAttribute('src') === src) return true;
    return false;
}

function manniello_social_mouse_lazy() {

    var link_check_social = plugins_url_wp + '/social-connect/js/polyfill-event-includes.min.js';

    if (typeof fbga_var !== 'undefined') {
        if (!(check_if_exists_js(link_check_social))) {

            // document.addEventListener('mousemove', loadSCMouse);
            // document.addEventListener('touchstart', loadSCMouse);

            var userInteractions = ["keydown", "mousemove", "wheel", "touchmove", "touchstart", "touchend"];
            userInteractions.forEach(function (event) {
                window.addEventListener(event, loadSCMouse, {passive: !0})
            });

            // Add dependency to avoid errors. Don't change the order
            function loadSCMouse() {

                loadjs([
                    plugins_url_wp + '/social-connect/lib/auth0.min.js',
                    plugins_url_wp + '/social-connect/js/polyfill-event-includes.min.js',
                    plugins_url_wp + '/social-connect/lib/mustache/mustache.min.js',
                    plugins_url_wp + '/social-connect/js/promise.min.js',
                    plugins_url_wp + '/social-connect/js/fetch.min.js',
                    plugins_url_wp + '/social-connect/js/promocode.min.js',
                    plugins_url_wp + '/social-connect/js/fetch-jsonp.min.js',
                    // Login after auth0
                    plugins_url_wp + '/social-connect/js/login.min.js'
                ], 'dependencysocial', {
                    async: false,
                });


                loadjs.ready('dependencysocial', {
                    success: function () {
                        // Load GuestAccount before Manniello Core Style

                        var bodySelect = jQuery('body');

                        // Check where insert the style, Theme and Page dependency
                        if (bodySelect.hasClass('manniello-single-theme')) {
                            if (!bodySelect.hasClass('home')) {
                                loadCSS_fast(plugins_url_wp + '/social-connect/css/guestaccount.css', 'guestaccount-css', 'manniello-single-main-css')
                                // loadCSS(plugins_url_wp + '/social-connect/css/guestaccount.css', document.getElementById('manniello-single-main-css'));
                            } else {
                                loadCSS_fast(plugins_url_wp + '/social-connect/css/guestaccount.css', 'guestaccount-css', 'manniello-critical-home-css')
                                // loadCSS(plugins_url_wp + '/social-connect/css/guestaccount.css', document.getElementById('manniello-critical-home-css'));
                            }
                        }
                        // else if (bodySelect.hasClass('manniello-group-theme')) {
                        //     if (!bodySelect.hasClass('home')) {
                        //         loadCSS(plugins_url_wp + '/social-connect/css/guestaccount.css', document.getElementById('manniello-group-main-css'));
                        //     } else {
                        //         loadCSS(plugins_url_wp + '/social-connect/css/guestaccount.css', document.getElementById('manniello-critical-home-css'));
                        //     }
                        // }
                        loadjs(
                            // Connected after login
                            plugins_url_wp + '/social-connect/js/connected.min.js'
                        );
                        console.log('Loaded interaction Social Connects assets');
                        // The next is to prevent errors with another dependency
                        loadjs.done();
                    }
                });

                // document.removeEventListener('mousemove', loadSCMouse);
                // document.removeEventListener('touchstart', loadSCMouse);
                userInteractions.forEach(function (event) {
                    window.removeEventListener(event, loadSCMouse, {passive: !0});
                });
            }
        } else {
            console.log('Social Connect assets already exists');
        }
    }
}

// LazyLoad for Google Map
function manniello_google_map_lazy(key, language) {
    var map = document.getElementById('map');
    if (map != null) {
        ;(function ($) {
            // var first_point_breadcrum = document.querySelector('.container-breadcrum');
            // var point_group = document.getElementById('icons-block');

            function loadGoogleLazy() {
                var bodyControl = jQuery('body');
                if ((check_if_exists_js(theme_url + '/js/map.min.js') && !bodyControl.hasClass('manniello-group-theme'))
                    || (bodyControl.hasClass('manniello-group-theme')) && !bodyControl.hasClass('home')) {
                    loadjs('//maps.google.com/maps/api/js?key=' + key + '&callback=intializedMap&v=3&libraries=places&language=' + language);
                    console.log('Google Map Intersected')
                } else {
                    loadjs(theme_url + '/js/map.min.js', {
                        success: function () {
                            loadjs('//maps.google.com/maps/api/js?key=' + key + '&callback=intializedMap&v=3&libraries=places&language=' + language)
                            console.log('Google Map Intersected')
                        }
                    });
                }
            }

            if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {

                var options = {
                    root: null,
                    rootMargin: '600px 0px 600px 0px',
                    //threshold size in % of the image that need to read to start the lazy loading
                    threshold: 0.01
                };

                var observer = new IntersectionObserver(
                    function (entries, observer) {
                        var isIntersecting = typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0;
                        if (isIntersecting) {
                            loadGoogleLazy();
                            // observer.unobserve(first_point_breadcrum);
                            observer.unobserve(map);
                            // if(point_group != null) {
                            //     observer.unobserve(point_group);
                            // }
                        }
                    },
                    options
                );

                // Observer with two points of observer. Top and Map
                // observer.observe(first_point_breadcrum);
                observer.observe(map);
                // if(point_group != null) {
                //     observer.observe(point_group);
                // }

            } else {
                loadGoogleLazy()
            }

        })(jQuery);
    }
}


function manniello_fix_keyboard_gf() {
    ;(function ($) {
        var formCheck = jQuery('.manniello-gf-form_wrapper');
        var datePickerCheck = jQuery('.datepicker, .hasDatepicker');
        if (formCheck.length && datePickerCheck.length) {
            datePickerCheck.attr('inputmode', 'none').attr('readonly', 'readonly');

            // Safari
            datePickerCheck.on('focus', function () {
                jQuery(this).trigger('blur');
            });
        }
    })(jQuery);
}

function manniello_double_click_blocks() {
    var checkWeddings = jQuery('.page-template-template-group-weddings')
    if (checkWeddings.length) {
        ;(function ($) {
            jQuery('a[dblclick]').click(function (e) {
                return false; // Disable single click
            })

            // jQuery('a[dblclick]').click(function (e) {
            //     return false; // Disable single click
            // }).dblclick(function (e) {
            //     // On double click go to the URL from href value
            //     window.open(this.href, '_blank');
            // })

            jQuery('a[dblclick] .more-info-weddings').click(function (e) {
                var linkclick = jQuery(this).attr('data-link');
                window.open(linkclick, '_blank');
            })
        })(jQuery);
    }
}

function manniello_test_get_height_width_empty() {
    // console.time('checktimer');
    var start = window.performance.now();
    var allImages = jQuery('img');

    function imgCheck(attributeOne, attributeTwo, attributeThree) {
        allImages.each(function () {
            var $this = jQuery(this);
            if (!$this.attr(attributeOne) || !$this.attr(attributeTwo) || !$this.attr(attributeThree)) {
                console.log('Missing:')
                console.log($this[0]);
            }
        });

    };

    function checkImages() {
        imgCheck('width', 'height', 'alt');
        setTimeout(checkImages, 15000);
        console.log('>>> Run Control Images');
    }

    // Check time

    checkImages();
    // console.timeEnd('checktimer');
    var end = window.performance.now();
    var time = end - start;
    console.log('Took', time.toFixed(4), 'ms to Generate');

}

// manniello_test_get_height_width_empty();

// Reload webpage on change orientation (For Parallax size)
if (window.DeviceOrientationEvent) {
    window.addEventListener('orientationchange', function () {
        location.reload();
    }, false);
}

// Disable wheel to avoid scrolling when the windows open
function manniello_handlerWheel(e) {
    e.preventDefault();
}

function manniello_disableMouseScroll() {
    window.addEventListener("wheel", manniello_handlerWheel, {passive: false})
}

function manniello_enableScroll() {
    window.removeEventListener("wheel", manniello_handlerWheel)
}

function manniello_disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
        window.scrollTo(x, y);
    };
}

function manniello_enableScrolling() {
    window.onscroll = null;
}

function manniello_gravityDate() {
    jQuery('.gravity_date_input input').attr('autocomplete', 'off');

    if (language == 'en') {
        // Specific behavior for language EN, add AM and PM
        jQuery('.gravity_date_input input').datetimepicker({
            defaultDate: new Date(),
            minDate: '0',
            gotoCurrent: true,
            closeText: 'OK',
            timeFormat: 'HH:mm tt',
            stepHour: 1,
            stepMinute: 10,
            hourMin: 0,
            hourMax: 23,
            showButtonPanel: true,
            timeText: gravity_date_time,
            hourText: gravity_date_hour,
            minuteText: gravity_date_minute,
            beforeShow: function (input, inst) {
                jQuery(inst.dpDiv).addClass('gf-timepicker');
            }
        });
    } else {
        jQuery('.gravity_date_input input').datetimepicker({
            defaultDate: new Date(),
            minDate: '0',
            gotoCurrent: true,
            closeText: 'OK',
            timeFormat: 'HH:mm',
            stepHour: 1,
            stepMinute: 10,
            hourMin: 0,
            hourMax: 23,
            showButtonPanel: true,
            timeText: gravity_date_time,
            hourText: gravity_date_hour,
            minuteText: gravity_date_minute,
            beforeShow: function (input, inst) {
                jQuery(inst.dpDiv).addClass('gf-timepicker');
            }
        });
    }
}

// Dom ready
jQuery(document).ready(function ($) {
    ;(function ($) {
        var bodySelectCheck = document.querySelector('body');

        // Size screen
        manniello_refresh_size_queries();

        // Menu fixed
        manniello_menu_fixed();

        manniello_ewdelay_or_interaction_js();
        manniello_slick_fullwidth_lazy();

        // Parallax
        if (!is_mobile) {
            manniello_run_parallax_js();
        }

        // Fancybox
        manniello_fancybox_popup();
        manniello_fancybox_callback();

        // Menu Open
        manniello_menu_open();


        // Dynamic Calendar
        manniello_dynamic_calendar();
        if (bodySelectCheck.classList.contains('manniello-single-theme')) {
            manniello_social_mouse_lazy();
        }

        // Load only Homepage
        if (bodySelectCheck.classList.contains('home')) {
            manniello_icons_lazy();
            if (bodySelectCheck.classList.contains('manniello-single-theme')) {
                manniello_instagram_lazy();
            }
        } else {
            // Sliders. In Homepage (Mobile & Desktop) run with ewdelay. The rest of the pages run this:
            manniello_slick_default_gallery();
            // Gravity Date. Not Homepage
            manniello_gravityDate();
        }

        // IntersectionObserver
        manniello_lazy_load_img();
        manniello_lazy_load_bg_img();


        // Added in ewscript on every page
        manniello_dynamic_lazy();
        if (bodySelectCheck.classList.contains('manniello-single-theme')) {
            // Social Connect
            manniello_close_login();
            // Menu Second
            if (!is_mobile) {
                mannielo_menu_second();
            }
            manniello_secondmenu_slider();
        } else if (bodySelectCheck.classList.contains('manniello-group-theme')) {
            manniello_group_dynamic();
            // Load the Booking NoPrices All
            // manniello_booking_group();
            manniello_calendar_all();
        }

        // Scroll to top
        if (!bodySelectCheck.classList.contains('error404')) {
            manniello_scroll_top();
        }

        // Fix Smooth Scroll (Safari)
        manniello_smooth_scroll_fix();
        manniello_newsletter_lazy();

        // Filters
        if (!is_mobile && bodySelectCheck.classList.contains('manniello-single-theme')) {
            manniello_filter_rooms();
            manniello_filter_offers();
            manniello_filter_photogallery();
        }

        if (bodySelectCheck.classList.contains('manniello-group-theme')) {
            manniello_filter_group_offers();
        }

        if (is_mobile && bodySelectCheck.classList.contains('manniello-single-theme')) {
            var grid_rooms = $('.filters-rooms');
            if (grid_rooms.length > 0) {
                var filterselect = $('#select-filter-rooms');
                var item_block = '.item-room-h'
                manniello_filter_by_select(grid_rooms, filterselect, item_block);
            }

            var grid_offers = $('.offers-block');
            if (grid_offers.length > 0) {
                var filterselect_of = $('#select-filter');
                var item_block_of = '.offers-item';
                manniello_filter_by_select(grid_offers, filterselect_of, item_block_of);
            }

            var grid_offers = $('.photogallery-block');
            if (grid_offers.length > 0) {
                var filterselect_of = $('#select-filter');
                var item_block_of = '.item-photogallery';
                manniello_filter_by_select(grid_offers, filterselect_of, item_block_of);
            }
        }

        // Food menu
        if (bodySelectCheck.classList.contains('manniello-single-theme')) {
            manniello_menu_food_movement_fix();
        }

        // Google Map Lazy with IO
        manniello_google_map_lazy(api_key, language);

        // WEBSDK. Run with ewdelay
        // manniello_web_sdk_offers();

        if (is_mobile) {
            manniello_fix_keyboard_gf();
            manniello_double_click_blocks();
        }

    })(jQuery);
});

// Run when the screen is resized
jQuery(window).smartresize(function () {
    manniello_secondmenu_slider();
});